/* NewsComponent.css */

.news-container {
    width: auto;
    max-width: 1150px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 0px;
    text-align: left;
}

.news-item {
    margin-bottom: 40px;
    border-bottom: 1px solid black;
    padding-bottom: 20px;
}

.hS {
    font-size: 24px;
    color: black;
    margin-bottom: 10px;
    text-align: left;
}

.pS {
    font-size: 16px;
    color: black;
    line-height: 1.6;
    text-align: left;
}