body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
}

body::before {
  content: "";
  position: fixed;
  /* This will keep the background fixed during scrolling */
  top: 0;
  left: 0;
  width: 100vw;
  /* This will make the image full width */
  height: 100vh;
  /* This ensures the image covers the full viewport height */
  background-image: url('./assets/Leave.png');
  background-repeat: no-repeat;
  background-position: center center;
  /* Centers the image */
  background-size: cover;
  /* Cover ensures the background covers the area, might crop the image */
  opacity: 0.1;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}